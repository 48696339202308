<template>
  <vs-dialog overflow-hidden v-model="$store.state.editing_html_content" :full-screen="full_screen">
    <div ref="rich_editor" class="rich_editor">
      <iframe ref="iframe"/>
    </div>

    <template #footer>
      <div class="footer-dialog">
        <vs-button block @click="save">
          Hoàn thành
        </vs-button>
      </div>
    </template>

  </vs-dialog>
</template>

<script>
import Summernote from "@/util_components/Summernote";

export default {
  name: "RichTextEditor",
  data() {
    return {
      content: ''
    }
  },
  created() {
    this.content = unescape(this.$store.state.editing.component_data.innerHTML)
  },
  mounted() {
    let iframe = this.$refs.iframe

    iframe.contentWindow.document.open()
    iframe.contentWindow.document.write(Summernote(this.content, JSON.stringify(this.links), this.$store))
    iframe.contentWindow.document.close()
  },
  computed: {
    links() {
      try {
        let res = []

        let common_data = this.$store.state.common_data

        let pages = common_data.pages.map(page => {
          if (page.path === 'home') return {
            name: `Trang: ${page.name}`,
            path: '/'
          }
          return {
            name: `Trang: ${page.name}`,
            path: '/' + page.path
          }
        })
        let categories = common_data.categories_id ? Object.keys(common_data.categories_id).map(key => {
          return {
            name: `Nhóm hàng: ${common_data.categories_id[key]}`,
            path: `/shop#category_id=${key}`
          }
        }) : []
        let tags = common_data.product_tags ? common_data.product_tags.map(tag => {
          return {
            name: `Tag: ${tag.name}`,
            path: `/shop#tag_id=${tag.id}`
          }
        }) : []
        let collections = common_data.collections ? common_data.collections.map(collection => {
          return {
            name: `Bộ sưu tập: ${collection.name}`,
            path: `/shop#collection=${collection.string_id}`
          }
        }) : []
        let brands = common_data.brands ? Object.keys(common_data.brands).map(key => {
          return {
            name: `Nhãn hiệu: ${common_data.brands[key]}`,
            path: `/shop#brand_id=${key}`
          }
        }) : []

        let blog_categories = Object.keys(common_data.blog_categories).map(key => {
          return {
            name: `Thể loại blog: ${common_data.blog_categories[key]}`,
            path: `/blogs?category=${key}`
          }
        })
        let blog_tags = Object.keys(common_data.blog_tags).map(key => {
          return {
            name: `Tag blog: ${common_data.blog_tags[key]}`,
            path: `/blogs?tag=${key}`
          }
        })
        // let blog_authors = Object.keys(common_data.blog_authors).map(key => {
        //   return {
        //     name: `Tác giả blog: ${common_data.blog_authors[key]}`,
        //     path: `/blogs#author=${common_data.blog_authors[key]}`
        //   }
        // })

        res = res.concat(pages)
            .concat(categories)
            .concat(tags)
            .concat(collections)
            .concat(brands)
            .concat(blog_categories)
            .concat(blog_tags)
            .concat([
              {
                name: 'Giỏ hàng',
                path: '/shop/cart'
              },
              {
                name: 'Gọi điện',
                path: 'tel: số_điện_thoại'
              },
              {
                name: 'Gửi mail',
                path: 'mailto:tên_mail'
              },
            ])
        // .concat(blog_authors)

        return res

      } catch (error) {
        console.error("đã có lỗi xảy ra", error)
        console.log("links > computed > QuickEditor");
        return ''
      }
    },
    full_screen() {
      return this.$store.state.editing.$el.getBoundingClientRect().height > 500 || this.$store.state.editing.$el.getBoundingClientRect().width > 1000
    }
  },
  methods: {
    save() {
      let innerHTML = this.$refs.iframe.contentWindow.document.querySelector('.note-editable').innerHTML
      if (this.$store.state.editing.$options.name === 'BaseDetailBlogComponent') {
        this.$store.state.editing.save({content: innerHTML})
      } else {
        this.$store.state.editing.save(escape(innerHTML))
      }
      this.$store.state.editing_html_content = false
    }
  }

}
</script>

<style scoped lang="scss">
::v-deep {
  .vs-dialog {
    width: 1140px !important;
    height: calc(100% - 30px) !important;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    .vs-dialog__footer {
      width: 100%;
    }
  
    .vs-dialog__content {
      max-height: calc(100% - 55px);
      flex: 1;
      display: flex;
    }
    .rich_editor {
      width: 100%;
    }
  
    iframe {
      width: 100%;
      height: 100%;
      border: none !important;
      // padding-top: 5px;
    }
  
    button {
      font-family: Montserrat, sans-serif !important;
    }
  }
}

</style>