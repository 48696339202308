// <script src="https://cdn.deepsel.com/summernote-vi-VN.min.js"></script>
export default (html, links, store) => {
  return `
  <html>
    <head>
        <meta charset="UTF-8">
        <link href='http://fonts.googleapis.com/css?family=Montserrat&display=swap' rel='stylesheet'>
        <link href='http://fonts.googleapis.com/css?family=Quicksand&display=swap' rel='stylesheet'>


        <script src="https://code.jquery.com/jquery-3.5.1.min.js"></script>
        <link href="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css" rel="stylesheet">
        <script src="https://stackpath.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
        
        <script src="https://kit.fontawesome.com/d097e88e4a.js" crossorigin="anonymous"></script>
        <link href='https://unpkg.com/boxicons@2.0.7/css/boxicons.min.css' rel='stylesheet'>

        <link href='https://cdnjs.cloudflare.com/ajax/libs/summernote/0.8.20/summernote.min.css' rel='stylesheet'>
    
        ${process.env.NODE_ENV === "development" ?
          `
            <script src="https://international.fpt.edu.vn/core_builder_v2/static/summernote/dist/summernote.min.js"></script>
            <script src="https://international.fpt.edu.vn/core_builder_v2/static/summernote/dist/lang/summernote-vi-VN.min.js"></script>
            <script defer src="https://international.fpt.edu.vn/core_builder_v2/static/summernote/dist/plugin/video/image-captionit.js"></script>
            <script defer src="https://international.fpt.edu.vn/core_builder_v2/static/summernote/dist/plugin/video/summernote-video-attributes.js"></script>
          ` : `
            <script src="/core_builder_v2/static/summernote/dist/summernote.min.js"></script>
            <script src="/core_builder_v2/static/summernote/dist/lang/summernote-vi-VN.min.js"></script>
            <script defer src="/core_builder_v2/static/summernote/dist/plugin/video/image-captionit.js"></script>
            <script defer src="/core_builder_v2/static/summernote/dist/plugin/video/summernote-video-attributes.js"></script>
          `
        }

        <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js"></script>
        <style>
          .panel {
            margin-bottom: 0
          }
        </style>

    </head>
    <body>
    
    <div id="summernote">${html}</div>
    <script>
        $(document).ready(function () {      
          $('#summernote').summernote({
            callbacks: {
              onImageUpload: async function(files) {
                let upload_files = [];

                function readFileAsDataURL(file) {
                  return new Promise((resolve) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                      resolve({
                        name: file.name,
                        image: reader.result.split(",")[1],
                      });
                    };
                  });
                }

                for (let i = 0; i < files.length; i++) {
                  let file = await readFileAsDataURL(files[i])
                  upload_files.push(file);
                }

                axios.post("/upload_image",{
                  jsonrpc: "2.0",
                  method: "call",
                  params: {
                    datas: upload_files,
                    page_path: '${store.state.page_path}',
                  },
                })
                  .then((res) => {
                    $('#summernote').summernote('insertImage', res.data.result[0].url)
                  })
                }
              },
              toolbar: [
                  ['style', ['style']],
                  ['font', ['bold', 'italic', 'underline', 'strikethrough', 'clear', 'height']],
                  ['fontname', [
                      'fontname',
                      'fontsize',
                      // 'fontsizeunit'
                  ]
                  ],
                  ['color', ['color']],
                  ['para', ['ul', 'ol', 'paragraph']],
                  ['table', ['table', 'hr']],
                  ['insert', ['link', 'picture','video']],
                  ['view', ['superscript', 'subscript', 'codeview']],
                  // ['do', ['undo', 'redo',]],
              ],
              imageAttributes: {
                icon: '<i class="note-icon-pencil"/>',
                figureClass: 'figureClass',
                figcaptionClass: 'captionClass',
                captionText: 'Caption Goes Here.',
                manageAspectRatio: true
              },
              popover: {
                image: [
                  ['custom', ['captionIt']],
                  ['imagesize', ['resizeFull', 'resizeHalf', 'resizeQuarter', 'resizeNone']],
                  ['remove', ['removeMedia']],
                  ['custom', ['imageAttributes']],
                ],
              },
              fontNames: ['Arial', 'Arial Black', 'Bradley Hand', 'Comic Sans MS', 'Courier New', 'Helvetica', 'Helvetica Neue', 'Lucida Grande', 'Montserrat', 'Quicksand', 'Times New Roman', 'Verdana'],
              fontNamesIgnoreCheck: ['Montserrat', 'Quicksand'],
              disableDragAndDrop: true,
              spellCheck: false,
              focus: true,
              disableResizeEditor: true,
              disableResizeImage: true,
              followingToolbar: true,
              lang: 'vi-VN',
              links: ${links}
          });
        });
    </script>
    
    </body>
  </html>
  `
}